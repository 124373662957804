import React from "react";
import "./PromoSection.scss";
import { useTranslation } from "react-i18next";

import ok from "./ok.svg";
import arrowRight from '../ServicesPage/images/arrow-right-gold.svg';

function PromoSection() {
	const { t } = useTranslation();

	return (
		<section className="promo-section">
			<div className="container">
				<div className="promo-section__content">
					<div className="promo-section__content-left">
						<p>{t("promo1")}</p>
						<p>{t("promo2")}</p>
						<button className="servicesBtn">
							
							<a href="#contact">{t("promo3")} <img src={arrowRight} alt="" /></a>
						</button>
					</div>
					<div className="promo-section__content-right">
						<div className="promo-section__content-right__item">
							<img src={ok} alt="" />
							<p> {t("promo4")}</p>
						</div>
						<div className="promo-section__content-right__item">
							<img src={ok} alt="" />
							<p> {t("promo5")}</p>
						</div>
						<div className="promo-section__content-right__item">
							<img src={ok} alt="" />
							<p> {t("promo6")}</p>
						</div>
						<div className="promo-section__content-right__item">
							<img src={ok} alt="" />
							<p> {t("promo7")}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default PromoSection;
