import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/Header/Header";
import FirstSection from "./components/FirstSection/FirstSection";
import SecondSection from "./components/SecondSection/SecondSection";
import ThirdSection from "./components/ThirdSection/ThirdSection";
import FourSection from "./components/FourSection/FourSection";
import FiveSection from "./components/FiveSection/FiveSection";
import Footer from "./components/Footer/Footer";

import RatePage from "./components/RatePage/RatePage";
import ServicesPage from './components/ServicesPage/ServicesPage';
import WhyUsPage from './components/WhyUsPage/WhyUsPage';
import HowToPage from './components/HowToPage/HowToPage';
import ContactPage from './components/ContactPage/ContactPage';
import ThanksPage from "./components/ThanksPage/ThanksPage";

import Modal from "./components/Modal/Modal";

import PromoSection from "./components/PromoSection/PromoSection";

function App() {
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const timerId = setTimeout(() => {
		  setIsModalOpen(true);
		}, 60000);
	  
		return () => clearTimeout(timerId);
	  }, []);
	  
	  

	const closeModal = () => setIsModalOpen(false);

	return (
		<Router>
			<div className="App">
				<Header />
				<Routes>
					<Route path="/" element={
						<>
							<FirstSection />
							<PromoSection />
							<SecondSection />
							<ThirdSection />
							<FourSection />
							<FiveSection />
							<Modal isOpen={isModalOpen} onClose={closeModal} />
						</>
					} />
					<Route path="/rate" element={
						<>
							<RatePage />
							<Modal isOpen={isModalOpen} onClose={closeModal} />
						</>
					} />
					<Route path="/services" element={<>
							<ServicesPage />
							<Modal isOpen={isModalOpen} onClose={closeModal} />
						</>} />
					<Route path="/why-us" element={<>
							<WhyUsPage />
							<Modal isOpen={isModalOpen} onClose={closeModal} />
						</>} />
					<Route path="/how-to" element={<>
							<HowToPage />
							<Modal isOpen={isModalOpen} onClose={closeModal} />
						</>} />
					<Route path="/contacts" element={<>
							<ContactPage />
							<Modal isOpen={isModalOpen} onClose={closeModal} />
						</>} />
					<Route path="/thanks" element={
						<>
						<ThanksPage />
						</>
					} />
				</Routes>
				<Footer />
			</div>
		</Router>
	);
}

export default App;
