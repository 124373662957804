import React, { useEffect } from "react";
import "./FirstSection.scss";
import ButtonComponent from "../Button/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import discont from './images/discont.svg';

function FirstSection() {
  const { t } = useTranslation();

  // Функция для обработки кликов по якорным ссылкам с учётом фиксированного заголовка
  const handleAnchorClick = (e) => {
    const targetId = e.target.getAttribute("href");
    if (targetId && targetId.startsWith("#")) {
      e.preventDefault(); // Отключаем стандартное поведение
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        const offsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset - 115; // Смещение на -115px для фиксированного хедера
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth", // Плавная прокрутка
        });
      }
    }
  };

  useEffect(() => {
    // Добавляем обработчик клика на якорные ссылки
    const anchorLinks = document.querySelectorAll('a[href^="#"]');
    anchorLinks.forEach((link) => {
      link.addEventListener("click", handleAnchorClick);
    });

    // Очищаем обработчики при размонтировании
    return () => {
      anchorLinks.forEach((link) => {
        link.removeEventListener("click", handleAnchorClick);
      });
    };
  }, []);

  return (
    <section className="first-section">
      <div className="container">
        <div className="first-section__content">
          <div className="first-section__content-header">
            <h1>{t("h1")}</h1>
            <h2>{t("h1__2")}</h2>
            <div className="first-section__content-header__sub">
              <p>{t("firstsectioncontentheadersub")}</p>
              <span><img src={discont} alt="Discont" />{t("firstsectioncontentheadersub2")}</span>
              <span><img src={discont} alt="Discont" />{t("firstsectioncontentheadersub3")}<span>40%</span></span>
            </div>
          </div>
          <div className="first-section__content-btn">
            {/* Ссылка с якорем на секцию контактов */}
            <ButtonComponent url="#contact" text={t("firstsectioncontentbtn")} />
            <button className="servicesBtn">
              <Link to="/services">{t("firstsectioncontentbtn2")}</Link>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FirstSection;
